import {AndroidListenerShell} from "#/android/android-listener-shell";
import {Log} from "#/libs/fanfanlo/log/Log";
import i18n from "i18next";
import _ from "lodash";
import {autobind} from "core-decorators";
import {log} from "node:util";
import {logSetting} from "#/libs/fanfanlo/log/logSetting";

export const global = window.global || (window.global=window);
let webViewBridgeCount = 0;

export function callAndroidInterface(target:any, name:string, descriptor:any){
    const originalMethod = descriptor.value;
    descriptor.value = function (...args:any[]) {
        if(!this){
            throw new Error("no thi222s")
        }
        if(!this.isInApp)return new CallWebViewInterfaceResult(WebViewJs.errNotInApp);
        if(!this.android[name])return new CallWebViewInterfaceResult(WebViewJs.errNoFun);
        const data = originalMethod.apply(this, args);
        return new CallWebViewInterfaceResult(undefined, data);
    };

    return descriptor
}
export class CallWebViewInterfaceResult {
    error:Error|undefined
    err:Error|undefined
    errMsg:string|undefined
    data:any
    constructor(error:Error|undefined, data:any = undefined) {
        this.error = error;
        this.err = error;
        this.errMsg = error?.message;
        this.data = data;
        // console.log("tttttttttttttttttttttthis data is", error, this.data);
    }
}

@autobind
export class WebViewJs {

    static errNotInApp = new Error(i18n.t("webviewjs.error.notInAndroid"))
    static errNoFun = new Error(i18n.t("webviewjs.error.noMethod"))
    static instance:WebViewJs;
    log = Log.createCountedLogger(false, this.constructor.name);
    // print = this.log.print
    javascriptInterfaceName = "android";
    androidListener = new AndroidListenerShell(this);
    constructor() {
        WebViewJs.instance = this;
    }

    get android():Record<any, any> {
        return (window as any)[this.javascriptInterfaceName];
    }

    get isInApp() {
        return !!this.android;
    }

    init = () => {
        this.androidListener.init();
        this.proxyConsoleLog();
    }

    callInterface = (funName:string, ...args:any[]) => {
        // if (!this.isInApp) return WebViewJs.errNotInApp
        // if (!this.android[funName]) return WebViewJs.errNoFun;
        args.unshift(funName);
        return this.android[funName].apply(null, args)
    }
    @callAndroidInterface
    reload(){
        return this.android.reload();
    }
    @callAndroidInterface
    callJavaToJsDataString(){
        return this.android.callJavaToJsDataString();
    }
    @callAndroidInterface
    onDocumentReady(){
        return this.android.onDocumentReady();
    }
    @callAndroidInterface
    onJsCallEvent (type:string, value:string){
        return this.android.onJsCallEvent(type, value);
    }
    @callAndroidInterface
    dispatchData(targetName:string, eventType:string, data:string){
        return this.android.dispatchData(targetName, eventType, data);
    }
    @callAndroidInterface
    callExitFromJs(){
        return this.android.callExitFromJs();
    }
    @callAndroidInterface
    createWindow(json:string) {
        return this.android.createWindow(json);
    }

    @callAndroidInterface
    readAllPackages() {
        return this.android.readAllPackages();
    }

    @callAndroidInterface
    openApp(pn:string) {
        return this.android.openApp(pn);
    }

    @callAndroidInterface
    openHelperTools() {
        return this.android.openHelperTools();
    }

    @callAndroidInterface
    openSelfByPackage(){
        return this.android.openSelfByPackage();
    }
    @callAndroidInterface
    callReadClipboard() {
        // if (!this.isInApp) return "";
        // if (!this.android.onCallReadClipboard) return "";
        return this.android.onCallReadClipboard();
    }

    // 此方法当前仅供webviewjs内部调用，其它地方调用请shellListen
    @callAndroidInterface
    private listen (target:string, type:string, callbackEventType:string, once:boolean) {
        let index = this.android.listen(target, type, callbackEventType, once);
        return index;
    }
    // 此方法当前仅供webviewjs内部调用，其它地方调用请用this.androidListener.removeListen
    @callAndroidInterface
    private removeListen(target:string, index:number){
        return this.android.removeListen(target, index);
    }
    // type是target侦听的事件，callbackEventType是java把type事件的数据发送给js，js再次派发出来的事件名称
    // 这样能够避免不同target具有相同的type，js这边再次派发时就是相同的事件名了，但是它们又是不同的target派发的，用途有可能不同，所以是一个冲突。
    // 再一个，譬如js的a类希望返回的事件是eventTypeDog，b类希望返回的事件是eventTypeCat，这样就能做到隔离效果。
    shellListen(target:string, type:string, callbackEventType:string, once:boolean, fun:(data:any)=>void) {
        return this.androidListener.listen(target, type, callbackEventType, once, fun)
    }
    shellRemoveListen (target:string, index:number){
        this.removeListen(target, index);
        return this.androidListener.removeListen(index);
    }
    @callAndroidInterface
    printString(...args:any){
        let s = argsToString.apply(null, args)
        return this.android.printString(s)
    }
    @callAndroidInterface
    printError(...args:any){
        let s = argsToString.apply(null, args)
        return this.android.printError(s);
    }
    private proxyConsoleLog(){
        logSetting.init(this.printString, this.printError, this.isInApp)
        return;
        if(!this.isInApp)return;
        // return;
        function p (...args:any){
            WebViewJs.instance.printString.apply(undefined, args);
        }
        const log = console.log.bind(console);
        const error = console.error.bind(console)
        console.log = p.bind(console)
        // return;
        console.error = function (...args:any){
            WebViewJs.instance.printError.apply(undefined, args);
        }
    }
}



function prepareLog(){

}

function argsToString(...args:any){

    let s = "";
    for (let i = 0; i < args.length; i++) {
        let t = args[i];
        let a = "";
        if(_.isError(t)){
            a = `err message is ${t.message}, stack is ${t.stack}`
        }
        else if(_.isObject(t)){
            try{
                a = JSON.stringify(t)
            }catch (e){
                // console.log("printString-try error", e)
            }
            finally {}
        }
        else if(_.isString(t)){a = t}
        else{
            a = `${t}`
        }
        if(s.length > 0){
            s = `${s} `
        }
        s += a;
    }
    return s;
}
//可以这样初始化
// const webview = new WebView();
// 修改kotlin调用js时的方法名
// webview.androidListener.onCallJsEventName = "onCallJsEvent";
// 默认安卓端注入到webview的名字是android，在这里可以修改。
// webview.javascriptInterfaceName = "android";
// 属性配置完毕后进行初始化
// webview.init();

