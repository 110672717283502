import {callAndroidInterface, WebViewJs} from "#/android/WebViewJs";
import * as _ from "lodash";

export class AutoWebViewJs extends WebViewJs{
    static instance:AutoWebViewJs;
    constructor() {
        super();
        autoWebViewJs = this;
        AutoWebViewJs.instance = this;
    }
    static confToScript(conf:any){
        let c = _.merge({}, conf);
        let list = c.injects || [];
        let injects = [];
        for(let i = 0; i < list.length; i++){
            let item = list[i];
            let arr;
            if(_.isArray(item)){
                arr = item;
            }else if(_.isString(item)){
                arr = [item]
            }else if(_.isObject(item)){
                let i = item as any
                arr = [i.name, i.alias]
            }
            injects.push(arr);
        }
        c.injects = injects;
        var s = JSON.stringify(c);
        return s;
    }
    @callAndroidInterface
    onCallNowPackageNodesJsonInfo(){
        return this.android.onCallNowPackageNodesJsonInfo()
    }
    @callAndroidInterface
    onCallGetA11yServiceLiveInfo(){
        let {print, error, warn, logger} = this.log.sub(false, "fn_onCallGetA11yServiceLiveInfo");
        try{
            const s = this.android.onCallGetA11yServiceLiveInfo()
            print("service info is", s)
            const info = JSON.parse(s)
            return info;
        }catch (e){ print("onCallGetA11yServiceLiveInfo-try error", e) }
        finally {}
        return null;
    }
    @callAndroidInterface
    onCallOpenA11yPermission(){
        this.android.onCallOpenA11yPermission();
    }
    @callAndroidInterface
    callScript(script:string){
        return  this.android.callScript(script);
    }


    @callAndroidInterface
    callScriptRunner(script:string){
        return this.android.callScriptRunner(script);
    }
    // script conf not string
    callScriptByConf(conf:any){
        let s = AutoWebViewJs.confToScript(conf);
        return this.callScriptRunner(s)
    }
    permissionHasFloat(){
        let script = `var bl = com.fanfanlo.lib.permissions.FloatPermission.hasPermission();\n bl;`
        let res = this.callScript(script);
        if(res.data){
            let print = this.log.sub(false, "permissionHasFloat-try").print;
            try{ res.data = JSON.parse(res.data) }
            catch (e){ print("permissionHasFloat-try error", e) }
        }
        return res;
    }
    clipboardSetText(text:string){
        return this.callScript(`com.fanfanlo.lib.utils.ClipboardUtils.Companion.setText("${text}")`);
    }
}

export var autoWebViewJs:AutoWebViewJs;