import {storage} from "#/common/storage/storage";


const printLogToChromeKey = "printLogToChrome";
const printLogToAndroidKey = "printLogToAndroid";
type logFunc = (...args:any)=>void;
let androidLog:logFunc;
let androidError:logFunc;
let consoleLog = console.log.bind(console)
let consoleError = console.error.bind(console)
let printLogToCatlog = false
let  printLogToConsole =false
let inApp = false;
export const logSetting = {
    init:function (logFunc:logFunc, errorFunc:logFunc, isInApp:boolean){
        inApp = isInApp;
        androidLog = logFunc;
        androidError = errorFunc;
        printLogToCatlog = storage.getItem(printLogToAndroidKey) === true;
        printLogToConsole = storage.getItem(printLogToChromeKey) === true;
    },
    get isInApp(){
        return inApp;
    },
    get printLogToCatlog(){
        return printLogToCatlog;
    },
    set printLogToCatlog(print:boolean){
        printLogToCatlog = print;
        storage.setItem(printLogToAndroidKey, print)
    },
    get printLogToConsole(){
        return printLogToConsole;
    },
    set printLogToConsole(print:boolean){
        printLogToConsole = print;
        storage.setItem(printLogToChromeKey, print)
    },
}


function logProxy(...args: any) {
    print(androidLog, consoleLog, args)
}

function errorProxy(...args:any) {
    print(androidError, consoleError, args);
}
function print(androidFunc:logFunc, consoleFunc:logFunc, args:any[]){
    if(logSetting.printLogToConsole || !inApp){
        consoleFunc.apply(undefined, args);
    }
    if(logSetting.printLogToCatlog && androidFunc){
        androidFunc.apply(undefined, args);
    }
}
console.log = logProxy.bind(console);
console.error = errorProxy.bind(console);