import {Log} from "#/libs/fanfanlo/log/Log";

export class AndroidListenerShell {
    constructor(webview) {
        this.log = Log.createCountedLogger(false, this.constructor.name);
        this.print = this.log.print;
        this.listeningMap = {};
        this.onCallJsEventName = "onCallJsEvent"
        this.webview = webview;
    }
    init =()=>{
        if(!this.webview.isInApp)return;
        const that = this;
        global[this.onCallJsEventName] = function (event){
            that.print(that.onCallJsEventName, "receive message is", JSON.stringify(event))
            if(!event)return;
            try {
                for (const [index, value] of Object.entries(that.listeningMap)) {
                    if(value.callbackEventType != event.type)continue;
                    value.fun(event.data);
                    if(value.once){
                        delete that.listeningMap[index];
                        // webViewJsListen时传入了once，所以java端自己就卸载侦听了
                        // webViewJsRemoveListen(value.target, index)
                    }
                }
                return "ok"
            }catch (e){
                console.log('global on call js event error', e);
            }
        }
    }
    listen =(target, type, callbackEventType, once, fun,)=>{
        if(!this.webview)return -1;
        let res = this.webview.listen(target, type, callbackEventType, once);
        let index = res?.data || res;
        // console.log("iiiiiiiiiiiiindex2 is", res?.data, res?.errMsg, res?.error, index)
        if(!index)return -1;
        this.listeningMap[index] = {once, fun, target, type, callbackEventType}
        return index;
    }
    removeListen =(index)=>{
        return delete this.listeningMap[index];
    }
}